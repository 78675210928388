<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content">
      <div class="top-content-left">
        <img
          class="top-content-left-image"
          src="@/assets/images/svg/top-sec-img.svg"
          alt="">
      </div>
      <div class="top-content-text">
        <h1 class="top-content-text-title">
          Перемещаем клиентов <br>из цифрового мира в <br>Ваш реальный бизнес !
        </h1>
        <div class="">
          <RouterLink
            class="top-content-text-feedback"
            @click.passive="
            () => {
              scrollTo('#feedback', 2900, { offset: -50 })
          }"
            :to="`/`">
            <p class="top-content-text-feedback-item">
              Оставьте заявку
            </p>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw-pc(1619px);
  margin-left: auto;
  margin-right: auto;
}
.top {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw-pc(120px);
    &-left {
      width: 50%;
      margin-left: -5%;
      &-image {
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: get-vw-pc(70px);
      &-title {
        font-style: normal;
        font-weight: 800;
        font-size: get-vw-pc(65px);
        line-height: get-vw-pc(85px);
        padding-top: get-vw-pc(30px);
        padding-bottom: get-vw-pc(30px);
        color: #000000;
        padding-left: get-vw-pc(50px);
        border-left: get-vw-pc(6.42px) solid #000000;
        text-shadow: 0px get-vw-pc(4px) get-vw-pc(4px) rgba(0, 0, 0, 0.25);
      }
      &-feedback {
        width: get-vw-pc(212.8px);
        height: get-vw-pc(39.87px);
        border-radius: get-vw-pc(51px);
        border: get-vw-pc(4px) solid #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.4s;
        cursor: pointer;
        text-decoration: none;
        color: #FFFFFF;
        background: #000000;
        margin-left: get-vw-pc(30px);
        margin-top: get-vw-pc(40px);
        text-align: center;
        &:hover {
          color: #000000;
          background: #FFFFFF;
          transition: color 0.4s;
        }
        &-item {
          font-style: normal;
          font-weight: 700;
          font-size: get-vw-pc(18px);
        }
      }
    }
  }
}
</style>
